<template>
  <el-dialog custom-class="minWidthNeedAdd" :title="form.id ? '编辑撮合规则' : '新增撮合规则'" :visible.sync="visible" :close-on-click-modal="false" draggable>
  <el-row>
    <el-col :span="24">
      <el-form :model="form"   ref="dataFormRef" label-width="120px" formDialogRef v-loading="loading">
        <el-row>
          <el-col :span="4">
            <el-form-item label="是否匹配所属行政区">
              <el-switch v-model="form.matchRegion" :active-value="1" :inactive-value="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-show="form.matchRegion" label="选择所属行政区">
              <el-select v-model="form.regionId" placeholder="请选择所属行政区" clearable>
                <el-option v-for="item in land_area" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-form-item label="否匹配土地性质">
              <el-switch v-model="form.matchType" :active-value="1" :inactive-value="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-show="form.matchType" label="选择土地性质">
              <el-select v-model="form.landType" placeholder="请选择土地性质" clearable>
                <el-option v-for="itemType in land_type" :key="itemType.value" :label="itemType.label" :value="itemType.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4">
            <el-form-item label="是否匹配土地用途">
              <el-switch v-model="form.matchPurpose" :active-value="1" :inactive-value="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-show="form.matchPurpose" label="选择土地用途">
              <el-select v-model="form.landPurpose" placeholder="请选择土地用途" clearable>
                <el-option v-for="purposeItem in demand_purpose" :key="purposeItem.value" :label="purposeItem.label" :value="purposeItem.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4">
            <el-form-item label="是否匹配价格">
              <el-switch v-model="form.matchPrice"  :active-value="1" :inactive-value="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div v-show="form.matchPrice">
              <el-form-item label="最低价格">
                <el-input v-model="form.priceMin" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入最低价格" clearable />
              </el-form-item>
              <el-form-item label="最高价格">
                <el-input v-model="form.priceMax" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入最高价格" clearable />
              </el-form-item>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4">
            <el-form-item label="是否匹配土地面积">
              <el-switch v-model="form.matchSize" :active-value="1" :inactive-value="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div v-show="form.matchSize">
              <el-form-item label="最小面积">
                <el-input v-model="form.sizeMin" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入最小面积" clearable />
              </el-form-item>
              <el-form-item label="最大面积">
                <el-input v-model="form.sizeMax" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入最大面积" clearable />
              </el-form-item>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4">
            <el-form-item label="是否选择剩余年限" style="min-width: 200px">
              <el-switch v-model="form.matchYearLimit"  :active-value="1" :inactive-value="0" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div v-show="form.matchYearLimit">
              <el-form-item label="最小剩余年限">
                <el-input v-model="form.yearMin" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入最小剩余年限" clearable />
              </el-form-item>
              <el-form-item label="最大剩余年限">
                <el-input v-model="form.yearMax" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入最大剩余年限" clearable />
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button @click="visible = false">取消</el-button>
          <el-button type="primary" v-prevent-re-click @click="onSubmit" :disabled="loading">确认</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
  </el-dialog>
</template>

<script  setup>
import {nextTick, reactive, ref} from 'vue'
import { useDict } from "@/hooks/dict";
import {putMatchConfig, getMatchConfig, addMatchConfig} from "@/service/CommonService";
import {Message} from "element-ui";
import { preventReClick } from '@/utils/preventReClick'
const emit = defineEmits(['refresh']);
const {  land_type, land_area, demand_purpose } = useDict( 'land_type', 'land_area', 'demand_purpose');

const { land_purpose } = useDict('land_purpose')

const visible = ref(false);
const loading = ref(false);
const dataFormRef = ref();
const form = reactive({
  matchRegion: 1,
  landType: '',
  regionId: '',
  matchType: 0,
  matchPurpose: 0,
  landPurpose: '',
  matchPrice: 0,
  priceMin: '',
  priceMax: '',
  matchSize: 0,
  sizeMin: '',
  sizeMax: '',
  matchYearLimit: 0,
  yearMin: '',
  yearMax: '',
})

const openNewDialog = (id) => {
  visible.value = true;
  form.id = '';

  // 重置表单数据
  nextTick(() => {
    dataFormRef.value?.resetFields();
  });
    getMatchData();

};

const getMatchData = () => {
  // 获取数据
  loading.value = true;
  getMatchConfig()
      .then((res) => {
        Object.assign(form, res.data);
      })
      .finally(() => {
        loading.value = false;
      });
};

// 暴露变量
defineExpose({
  openNewDialog,
});
const onSubmit = () => {


  try {
    if (form.id == 1) {
      form.id = 0;
    }
    loading.value = true;
    form.id ?  putMatchConfig(form) :  addMatchConfig(form);
    Message.success(form.id ? '修改规则成功' : '添加规则成功');
    visible.value = false;
    emit('refresh');
  } catch (err) {
    // console.log(err);
    Message.error('添加失败 请稍后再试');
  } finally {
    loading.value = false;
  }
};
</script>
<style>
.minWidthNeedAdd{
  min-width: 1060px !important;
}
</style>
